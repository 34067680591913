<template>
  <div class="region-cities-country-locations mt-10 mx-30">
    <vz-skeleton
      v-for="i in count"
      :key="i"
      type="rounded"
      class="region-cities-country-location"
      height="25"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  country: {
    type: String,
    default: 'RU'
  }
})

const count = computed(() => {
  return props.country === 'RU' 
    ? 75
    : props.country === 'KZ'
      ? 3 
      : 1
})
</script>

<style lang="scss" scoped>
.region-cities-country {
  &-location {
    width: 110px;
    margin-bottom: 15px;
  }
}
</style>
