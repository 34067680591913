<template>
  <div class="region-cities">
    <div v-for="phone of countries" :key="phone.value" class="region-cities-country mb-10" :class="phone.value">
      <nuxt-link :to="getCountryLink(phone)" class="region-cities-country-title" @click="$hideDialog({ name: 'region' })" external>
        <img class="region-cities-country-flag" :src="phone.url" :alt="phone.name"/>
        <span class="region-cities-country-name">{{ phone.name }}</span>
        <vz-icon v-if="phone.about && changeCityOnSelect" name="arrow-right" class="ml-5" size="18" />
      </nuxt-link>
      <div v-if="locations.length" class="region-cities-country-locations mt-10">
        <nuxt-link
          v-for="(location, index) in countryLocations(phone.value)"
          :key="index"
          class="region-cities-country-location"
          :class="{ 'text-primary': currentCity.guid === location.guid }"
          :href="getLink(location)"
          :rel="getRel(location)"
          @click="onClick(location, $event)"
        >
          <span>{{ location.name }}</span>
        </nuxt-link>
      </div>
      <region-cities-country-locations-skeleton
        v-else
        :country="phone.value"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useCityStore } from '~/stores/city';
import { Location, PhoneItem } from '~/types/types';
import { phoneCountries } from '~/common/objects';
import { deepCopyObject, getFullLinkRedirect } from '~/common/functions';
import RegionCitiesCountryLocationsSkeleton from '~~/components/public/region-cities/region-cities-country-locations-skeleton.vue'
import {useDomainCountry} from '~/composables/states';

const emit = defineEmits(['change'])

const props = defineProps({
  changeCityOnSelect: {
    type: Boolean,
    default: true
  },
  isLocationsLinksEmpty: {
    type: Boolean,
    default: false
  },
  hideChina: {
    type: Boolean,
    default: false
  },
  ruLink: {
    type: String,
    default: ''
  }
})

const { currentCity, currentCityAbbr, rclCityList } = storeToRefs(useCityStore())
const locations = ref([] as Location[])
const host = useHost()
const domainCountry = useDomainCountry()

const countries = computed(() => {
  let result = deepCopyObject(phoneCountries)

  if (props.ruLink) {
    const ruCountry = result.find(country => country.value === 'RU')
    if (ruCountry) {
      ruCountry.about = props.ruLink
    }
  }

  result = result.filter(phoneCountry => {
    return countryLocations(phoneCountry.value).length
  })

  if (!props.hideChina) {
    result.push(
        {
          value: 'CH',
          url: '/svg/vz-china-flag.svg',
          phoneCode: '',
          mask: '',
          name: 'Китай',
          about: { name: 'china' }
        })
  }

  return result
})

onMounted (async () => {
  locations.value = deepCopyObject(rclCityList.value) as Location[]
})

function countryLocations(country: string): Location[] {
  return locations.value.filter(l => l.country === country)
}

function getCountryLink(phone: PhoneItem) {
  return props.changeCityOnSelect ? phone.about : ''
}

function getRel(location: Location): string {
  return (location.country === currentCity.value.country || props.isLocationsLinksEmpty) ? '' : 'nofollow'
}

function getLink(location: Location): string {
  if (location.country === currentCity.value.country || props.isLocationsLinksEmpty) {
    return ''
  }
  if (!props.changeCityOnSelect) {
    return `/terminals/?locationGuid=${location.guid}/`
  }

  const path = (useRoute().name === 'order-create-locationFrom_locationTo') || (useRoute().name === 'order-create-locations')
    ? '/'
    : useRoute().fullPath
  return getFullLinkRedirect(location, path, host.value)
}

function onClick(city: Location, e: Event): void {
  emit('change', city)
  if (!props.changeCityOnSelect || city.country !== domainCountry.value || props.isLocationsLinksEmpty) {
    return
  }

  e.preventDefault()
  currentCityAbbr.value = city.abbr || city.translit
  currentCity.value = city
}
</script>

<style lang="scss" scoped>
.region-cities {
  display: grid;
  grid-template-columns: repeat(5, auto);

  &-country {
    display: inline-block;
    margin-top: 30px;

    &-title {
      display: flex;
      align-items: center;
      color: $font-dark;
      text-decoration: unset;

      &[href] {
        span {
          text-decoration: underline;
        }
        &:hover {
          color: $primary;
        }
      }
    }

    &.RU {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 5;

      .region-cities-country-locations {
        display: block;
        column-count: 4;
        margin-left: 30px;

        @media screen and (max-width: $pub-md) {
          column-count: 4;
        }
      }

      @media screen and (max-width: $pub-xs) {
        grid-row-end: 6;
        .region-cities-country-locations {
          column-count: 2;
        }
      }

      @media screen and (max-width: 400px) {
        .region-cities-country-locations {
          column-count: 1;
        }
      }
    }

    &-locations {
      display: block;
      column-count: 1;
    }

    &-location {
      display: block;
      color: $font-low;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-decoration: underline;
      margin-bottom: 8px;
      cursor: pointer;
    }

    &-flag {
      width: 20px;
      height: 16px;
    }

    &-name {
      font-size: $fs-18;
      line-height: $fs-26;
      font-weight: 700;
      margin-left: 10px;
    }
  }
}
</style>
